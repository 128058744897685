
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>

              <!-- Table -->
              <div class="row justify-content-md-center mt-4">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="col-md-3"></div>
                    <div class="col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <label class="d-inline align-items-center m-2 text-end">
                          รหัสค่าแรง:</label
                        >
                        <b-form-input
                          v-model="filter.wageCode"
                          type="search"
                          placeholder="Search..."
                          class="form-control"
                          @keyup.enter="handleSearch"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <label class="d-inline align-items-center m-2 text-end">
                          ชื่อค่าแรง:
                        </label>
                        <b-form-input
                          v-model="filter.nameTh"
                          type="search"
                          placeholder="Search..."
                          class="form-control"
                          @keyup.enter="handleSearch"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-3">
                      <btnComponent @click="handleSearch" changeStyle="search">
                      </btnComponent>&nbsp;&nbsp;&nbsp;&nbsp;
                      <router-link :to="{ name: 'add-masterWages' }">
                        <b-button
                          type="button"
                          variant="primary"
                          class="btn btn-primary"
                        >
                          <i class="mdi mdi-plus me-1"></i>เพิ่มข้อมูล
                        </b-button>
                      </router-link>
                    </div>
                    <!-- <div class="col-sm-6 col-md-2">
                      <router-link :to="{ name: 'add-masterWages' }">
                        <b-button
                          type="button"
                          variant="primary"
                          class="btn btn-primary float-end"
                        >
                          <i class="mdi mdi-plus me-1"></i>เพิ่มข้อมูล
                        </b-button>
                      </router-link>
                    </div> -->
                  </div>
                </div>
              </div>
              <!-- Table -->
              <div class="row justify-content-md-center mt-2">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-12">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          แสดงผล&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChange"
                          ></b-form-select
                          >&nbsp;รายการ
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <tableData :fields="fields" :items="rowmasterWage">
                <template #cell(price)="rowmasterWage">
                  {{ Number(rowmasterWage.item.price).toLocaleString() }}
                </template>
                <template
                  #cell(index)="rowmasterWage"
                  v-if="this.currentPage > 1"
                >
                  {{
                    rowmasterWage.index + 1 + (currentPage * perPage - perPage)
                  }}
                </template>

                <template #cell(index)="rowmasterWage" v-else>
                  {{ rowmasterWage.index + 1 }}
                </template>
                <template #cell(action)="rowmasterWage">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                        :to="{
                          name: 'update-masterWages',
                          params: { wageId: rowmasterWage.item.wageId },
                        }"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>

                      <a
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="alert(rowmasterWage.item.wageId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </tableData>

              <!-- <p class="float-end">
                <b-button v-model="selected" size="sm" @click="selectAllRows"
                  >Select all</b-button
                >
                &nbsp;

                <b-button size="sm" @click="clearSelected"
                  >Clear selected</b-button
                >
              </p>

              <p>
                Selected Rows:<br />
                {{ selected }}
              </p> -->
              <br />
              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="col">
                    <div class="align-items-center">
                      หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                      {{ totalRecord }} รายการ
                    </div>

                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecord"
                          :per-page="perPage"
                          @change="handleChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import tableData from "@/components/tablecomponent";
import btnComponent from "@/components/btnComponent.vue";

export default {
  page: {
    title: "ข้อมูลค่าแรง",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, tableData, btnComponent },

  data() {
    return {
      loading: undefined,

      title: "ข้อมูลค่าแรงศูนย์บริการ",
      items: [
        {
          text: "หนักหลัก",
          href: "/",
        },
        {
          text: "ข้อมูลค่าแรงศูนย์บริการ",
          active: true,
        },
      ],
      totalPage: "",
      wageId: this.$route.params.wageId,
      selectMode: "multi",
      nameTh: "",
      branchId: [],
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      name: "",
      total: "",
      totalRecord: "",
      rowmasterWage: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: {
        wageCode: "",
        nameTh: "",
      },
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      from: "",
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "wageCode",
          label: "รหัสค่าแรง",
          sortable: true,
        },
        {
          key: "nameTh",
          label: "ชื่อค่าแรง(Th)",
          sortable: true,
        },
        {
          key: "nameEn",
          label: "ชื่อค่าแรง(En)",
          sortable: true,
        },
        {
          key: "price",
          label: "ราคา",
          sortable: true,
        },
        {
          key: "maxDcPer",
          label: "ส่วนลด(%)",
          sortable: true,
        },
        {
          key: "action",
          label: "",
        },
      ],
    };
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowmasterWage.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.rowmasterWage = this.items.length;
  },
  created() {
    this.getWage();
  },
  methods: {
    handleSearch() {
      this.getWage();
    },
    onFiltered(filteredItems) {
      this.rowmasterWage = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowmasterWage) {
      this.selected = rowmasterWage;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    getWage: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/central/wages", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            wageCode: this.filter.wageCode,
            // nameTh:this.filter.nameTh,
            nameTh: this.filter.nameTh,
          },
        })
        .then((response) => {
          this.rowmasterWage = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getWage();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getWage();
    },
    onEdit(item) {
      if (item.isEditing) item.isEditing = false;
      else this.$set(item, "isEditing", true);
    },
    deletemasterWage: function (rowmasterWage) {
      this.wageData = rowmasterWage;
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/central/wage/delete", {
          data: {
            wageId: this.wageData,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getWage();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowmasterWage) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deletemasterWage(rowmasterWage);
          }
        });
    },
  },
  middleware: "authentication",
};
</script>
